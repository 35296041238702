var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-card",
        { staticClass: "card-border text--black mb-4" },
        [
          _c(
            "b-row",
            [
              _vm.ticketoverview
                ? _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("h3", [
                        _vm._v(
                          "Ticket Status Overview: " +
                            _vm._s(_vm.ticketFilters.month) +
                            " " +
                            _vm._s(_vm.ticketFilters.year)
                        )
                      ]),
                      _c("BarChart", {
                        attrs: {
                          data: _vm.ticketChart,
                          options: _vm.chartOptions
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "6", "offset-md": "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/report/ticket")
                        }
                      }
                    },
                    [_vm._v("View Full Report")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "card-border text--black" },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("h3", [_vm._v("Companies")])
              ]),
              _vm.companyoverview
                ? _c(
                    "b-col",
                    {
                      staticStyle: { overflow: "auto" },
                      attrs: { cols: "12" }
                    },
                    [
                      _c("b-table", {
                        staticClass: "w-100",
                        attrs: {
                          items: _vm.companyoverview,
                          fields: _vm.columns
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(name)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "text--black u",
                                      attrs: {
                                        to: "/admin/company/" + data.item.id
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(data.item.name) + " ")]
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(organization)",
                              fn: function(data) {
                                return [
                                  _vm._v(
                                    " " + _vm._s(data.item.organization) + " "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1577068164
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "5" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/report/quickbooks")
                        }
                      }
                    },
                    [_vm._v("Quickbooks Report")]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "5", offset: "2" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/report/company")
                        }
                      }
                    },
                    [_vm._v("View Full Report")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }